@font-face {
  font-family: Circe;
  src: url("../fonts/circe_regular.woff");
}

@font-face {
  font-family: CirceBold;
  font-weight: initial;
  -webkit-font-smoothing: antialiased;
  src: url("../fonts/circe_bold.woff");
}

* {
  box-sizing: border-box;
}

h1 {
  font-family: CirceBold;
  font-weight: initial;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  font-size: 56px;
  line-height: 64px;
}

h2 {
  font-family: CirceBold;
  font-weight: initial;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  font-size: 40px;
  line-height: 48px;
}

p {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
}

body {
  background: #fff;
  min-width: 320px;
  margin: 0;
  padding: 0;
  font-family: Circe, sans-serif;
  font-size: 20px;
}

body > .top {
  z-index: 2;
  width: 100%;
  position: sticky;
  top: 0;
}

body > .top .menuWrapper {
  background-color: #536ced;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  padding: 24px;
  transition: background-color .2s, color .2s;
  display: flex;
}

body > .top.shadow .menuWrapper {
  box-shadow: 0 1px 4px #293da64d;
}

body > .top.white .menuWrapper {
  color: #232735;
  background-color: #fff;
  box-shadow: 0 1px 4px #293da64d;
}

body > .top.white #mainMenu.desktop .menuItem {
  color: #232735;
}

body > .top.white #mainMenu.desktop .transparent.menuButton {
  color: #232735;
  border-color: #232735;
}

body > .top.white .logoText {
  fill: #536ced !important;
}

body > .top.white .uxText, body > .top.white #mainMenu.mobile.active ~ .logo .logoText {
  fill: #fff !important;
}

body > .top.white #mainMenu.mobile.active ~ .logo .uxText {
  fill: #536ced !important;
}

body > .top .logo {
  align-items: center;
  display: flex;
}

body > .top .logo a {
  display: flex;
}

body > .top .logo .logoText {
  fill: #fff;
}

body > .top .logo .uxText {
  fill: #536ced;
}

body > .top .logo.mobile {
  z-index: 9;
  position: absolute;
  left: 30px;
}

body > header {
  color: #fff;
  background-color: #536ced;
  justify-content: center;
}

body > header .middle {
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 112px 0 80px;
  transition: padding .3s ease-out;
  display: flex;
}

body > header .middle .left, body > header .middle .right {
  flex-direction: column;
  flex: 100%;
  justify-content: center;
  display: flex;
}

body > header .middle .right {
  align-items: center;
  display: flex;
}

body > header .middle .right img {
  max-width: 100%;
}

body > header .middle .left .labels {
  gap: 16px;
  margin-top: 40px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
}

body > header .middle .left .labels > div {
  text-align: center;
}

body > header .middle .left .labels .desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

body > header.activeYotitle .middle {
  padding: 160px 0 80px;
}

body > header h1 {
  font-family: CirceBold;
  font-weight: initial;
  -webkit-font-smoothing: antialiased;
  margin: 0 0 12px;
  font-size: 56px;
  line-height: 64px;
}

body > header .subheader {
  margin-bottom: 40px;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}

body > main .block {
  padding: 40px 16px;
}

body > main .block:first-of-type {
  padding: 80px 16px;
}

body > main .block .content {
  max-width: 1200px;
  margin: 0 auto;
}

body > main .block .content h2 {
  text-align: center;
}

body > main .block .content .subtitle {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
}

body > footer .content {
  max-width: 1200px;
}

@media only screen and (width <= 1400px) {
  body > header .middle {
    max-width: 960px;
    padding: 112px 0 80px;
  }

  body > main .block .content, body > footer .content {
    max-width: 960px;
  }
}

@media only screen and (width <= 1280px) {
  body > header .middle {
    text-align: center;
    flex-direction: column-reverse;
    padding: 112px 16px 40px;
  }

  body > header .middle .subheader {
    margin-bottom: 32px;
    font-size: 20px;
  }

  body > main .block .content p, body > main .block .content .subtitle {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
  }

  body > main .block .content .subtitle {
    text-align: center;
  }

  body > footer .content {
    padding: 40px 16px;
  }
}

@media only screen and (width <= 768px) {
  body > header .middle {
    padding: 104px 16px 64px;
  }

  body > header .middle h1 {
    font-family: CirceBold;
    font-weight: initial;
    -webkit-font-smoothing: antialiased;
    font-size: 36px;
    line-height: 48px;
  }

  body > header .middle h2 {
    font-family: CirceBold;
    font-weight: initial;
    -webkit-font-smoothing: antialiased;
    font-size: 32px;
    line-height: 40px;
  }

  body > header .middle .subheader {
    margin-bottom: 32px;
  }

  body > header .middle .left .labels {
    justify-content: center;
  }

  body > main .block .content h1 {
    font-family: CirceBold;
    font-weight: initial;
    -webkit-font-smoothing: antialiased;
    font-size: 36px;
    line-height: 48px;
  }

  body > main .block .content h2 {
    font-family: CirceBold;
    font-weight: initial;
    -webkit-font-smoothing: antialiased;
    text-align: left;
    font-size: 32px;
    line-height: 40px;
  }

  body > main .block .content h3 {
    font-family: CirceBold;
    font-weight: initial;
    -webkit-font-smoothing: antialiased;
    font-size: 20px;
    line-height: 32px;
  }

  body > main .block .content .subtitle {
    text-align: left;
  }

  body > footer .content {
    padding: 40px 16px;
  }
}

@media only screen and (width <= 658px) {
  body > header.activeYotitle .middle {
    padding: 184px 0 80px;
  }
}

@media only screen and (width <= 480px) {
  body > header .middle {
    flex-direction: column-reverse;
    padding: 104px 16px 64px;
  }

  body > header .middle .left .buttons a {
    text-align: center;
    width: 256px;
    margin: 0 auto;
    display: block;
  }

  body > main .block {
    padding: 32px 16px;
  }

  body > footer .content {
    padding: 40px 16px;
  }
}

@media only screen and (width <= 400px) {
  body > header.activeYotitle .middle {
    padding: 208px 0 80px;
  }
}
/*# sourceMappingURL=common.796178a8.css.map */
