@use './variables.scss';

@font-face {
  font-family: Circe;
  src: url(../../assets/fonts/circe_regular.woff);
}

@font-face {
  @include variables.Bold;
  src: url(../../assets/fonts/circe_bold.woff);
}

$desctopTopOffset: 112px;
$mobileTopOffset: 104px;

* {
  box-sizing: border-box;
}

h1 {
  @include variables.H1;
  margin: 0;
}

h2 {
  @include variables.H2;
  margin: 0;
}

p {
  @include variables.P2;
  margin: 0;
}


body {
  margin: 0;
  padding: 0;
  min-width: 320px;
  font-family: 'Circe', sans-serif;
  font-size: 20px;
  background: variables.$ui_01;

  > .top {
    z-index: 2;
    position: sticky;
    top: 0;
    width: 100%;

    .menuWrapper {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 24px;
      height: 72px;
      background-color: variables.$neutral_01;
      align-items: center;
      transition: background-color 200ms, color 200ms;
    }

    &.shadow .menuWrapper {
      @include variables.shadow01;
    }

    &.white {
      .menuWrapper {
        background-color: variables.$ui_01;
        color: variables.$text_01;
        @include variables.shadow01;
      }
      
      #mainMenu.desktop {
        .menuItem {
          color: variables.$text_01;
        }

        .transparent.menuButton {
          color: variables.$text_01;
          border-color: variables.$text_01;
        }
      }

      .logoText {
        fill: variables.$neutral_01 !important;
      }

      .uxText {
        fill: variables.$ui_01 !important;
      }

      #mainMenu.mobile.active ~ .logo {
        .logoText {
          fill: variables.$ui_01 !important;
        }

        .uxText {
          fill: variables.$neutral_01 !important;
        }
      }
    }

    .logo {
      display: flex;
      align-items: center;

      a {
        display: flex;
      }

      .logoText {
        fill: variables.$ui_01;
      }

      .uxText {
        fill: variables.$neutral_01;
      }

      &.mobile {
        z-index: 9;
        position: absolute;
        left: 30px;
      }
    }
  }

  > header {
    justify-content: center;
    background-color: variables.$neutral_01;
    color: variables.$ui_01;

    .middle {
      transition: padding 300ms ease-out;
      max-width: 1200px;
      padding: $desctopTopOffset 0 80px;
      display: flex;
      gap: 40px;
      margin: 0 auto;

      .left,
      .right {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;
        flex: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      
      .right {
        display: flex;
        align-items: center;

        img {
          max-width: 100%;
        }
      }

      .left .labels {
        display: flex;
        gap: 16px;
        margin-top: 40px;
        @include variables.P4;
    
        > div {
          text-align: center;
        }

        .desc {
          @include variables.P4;
        }
      }
    }

    &.activeYotitle .middle {
      padding: calc($desctopTopOffset + 48px) 0 80px;
    }

    h1 {
      @include variables.H1;
      margin: 0 0 12px;
    }

    .subheader {
      @include variables.P1;
      margin-bottom: 40px;
    }
  }

  > main .block {
    padding: 40px 16px;

    &:first-of-type {
      padding: 80px 16px;
    }

    .content {
      max-width: 1200px;
      margin: 0 auto;

      h2 {
        text-align: center;
      }

      .subtitle {
        @include variables.P2;
        text-align: center;
      }
    }
  }

  > footer .content {
    max-width: 1200px;
  }
}

@media only screen and (max-width: 1400px) {
  body {
    > header .middle {
      max-width: 960px;
      padding: $desctopTopOffset 0 80px;
    }

    > main .block .content {
      max-width: 960px;
    }

    > footer .content {
      max-width: 960px;
    }
  }
}

@media only screen and (max-width: 1280px) {
  body {
    > header .middle {
      padding: $desctopTopOffset 16px 40px;
      flex-direction: column-reverse;
      text-align: center;

      .subheader {
        font-size: 20px;
        margin-bottom: 32px;
      }
    }

    > main .block {
      .content {
        p, .subtitle {
          @include variables.P2;
        }

        .subtitle {
          text-align: center;
        }
      }
    }

    > footer .content {
      padding: 40px 16px;
    }
  }
}

@media only screen and (max-width: 768px) {
  body {
    > header .middle {
      padding: $mobileTopOffset 16px 64px;

      h1 {
        @include variables.H1Mobile;
      }
      
      h2 {
        @include variables.H2Mobile;
      }

      .subheader {
        margin-bottom: 32px;
      }

      .left .labels {
        justify-content: center;
      }
    }

    > main .block .content {
      h1 {
        @include variables.H1Mobile;
      }

      h2 {
        @include variables.H2Mobile;
        text-align: left;
      }

      h3 {
        @include variables.H3Mobile;
      }

      .subtitle {
        text-align: left;
      }
    }

    > footer .content {
      padding: 40px 16px;
    }
  }
}

@media only screen and (max-width: 658px) {
  body > header.activeYotitle .middle {
    padding: calc($desctopTopOffset + 72px) 0 80px;
  }
}

@media only screen and (max-width: 480px) {
  body {
    > header .middle {
      padding: $mobileTopOffset 16px 64px;
      flex-direction: column-reverse;

      .left .buttons a {
        display: block;
        text-align: center;
        margin: 0 auto;
        width: 256px;
      }
    }

    > main .block {
      padding: 32px 16px;
    }

    > footer .content {
      padding: 40px 16px;
    }
  }
}

@media only screen and (max-width: 400px) {
  body > header.activeYotitle .middle {
    padding: calc($desctopTopOffset + 96px) 0 80px;
  }
}

